import { useParams } from "react-router-dom";
import { PageContext } from "../../page-context";
import { useContext, useEffect, useState } from "react";
import { axiosInstance, LODE_API } from "../../util/util";
import { MdClose } from "react-icons/md";

export default function PluginEdit() {

    const { screenSize } = useContext(PageContext);
    const { id } = useParams();
    const [userData, setUserData] = useState(null);

    const [pluginNotes, setPluginNotes] = useState({});
    const [pluginIps, setPluginIps] = useState({});
    const [pluginStatistics, setPluginStatistics] = useState(null);
    const [plugins, setPlugins] = useState([]);
    const [sessionData, setSessionData] = useState();
    const [ips, setIps] = useState([]);
    const [comparableIps, setComparableIps] = useState([]);
    const [error, setError] = useState("");
    const [savingChanges, setSavingChanges] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        axiosInstance.get("https://lode.gg/api/landing/plugins", { signal: controller.signal })
            .then((res) => setPlugins(res?.data ?? []))
            .catch(() => null);

        return () => controller.abort();
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        axiosInstance.get(LODE_API + "/landing/user", { signal: controller.signal })
            .then((res) => setUserData(res.data))
            .catch(() => null);
        return () => controller.abort();
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        axiosInstance.get("https://lode.gg/api/users/plugins", { signal: controller.signal }).then((res) => {
            const data = res?.data;
            setPluginIps(data.plugins ?? {});
            setPluginNotes(data.notes ?? {});
        }).catch(() => null);

        return () => controller.abort();
    }, [pluginStatistics]);

    useEffect(() => {
        if (plugins.length > 0) {
            const sessionData = plugins.find(plugin => plugin.id === id);
            if (!sessionData)
                window.location.href = "/dashboard/plugins";
            else {
                setSessionData(sessionData);
                setIps(pluginIps[sessionData.id] ?? []);
            }
        }
    }, [plugins, pluginIps]);

    const validateIp = (ip) => {
        // Simple regex for validating IPv4 addresses
        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipRegex.test(ip);
    };

    const addIpToWhitelist = () => {
        const value = document.getElementById("#ip-whitelist").value.trim();

        // reset value
        document.getElementById("#ip-whitelist").value = "";

        if (!value) {
            setError("Please enter an IP address.");
            return;
        }
        if (!validateIp(value)) {
            setError("Invalid IP address.");
            return;
        }
        if (ips.includes(value)) {
            setError("This IP address is already whitelisted.");
            return;
        }

        setIps((prevIps) => [...prevIps, value]);
        setError(""); // Clear any existing error
    };

    return <div className="flex flex-row items-center justify-center">
        {sessionData && <div className="flex flex-col items-center justify-center">
            <div className="bg-[#121212] border border-quaternary-color px-5 py-5 w-[30rem] md:w-[50rem] xl:w-[80rem] rounded-xl">
                <div className="flex flex-row items-center justify-between">
                    <div>
                        <h1 className="text-4xl">{sessionData.title}</h1>
                        <p className="text-gray-400 mt-2">Premium Plugins require server whitelisting to prevent distribution and fraud.<br />You can only add upwards to 3 servers ips per plugin.</p>
                    </div>
                    <img src={sessionData.icon_url} alt="icon" width={100} className="rounded-xl" />
                </div>
                <div>
                    <div className="flex flex-row justify-between items-center mt-5">
                        <input id="#ip-whitelist" placeholder="Add a server IP to whitelist" className="w-full px-5 py-2 rounded-xl bg-[#343434] mr-2"></input>
                        <button onClick={addIpToWhitelist} className="bg-[#343434] px-5 py-2 rounded-xl w-[200px]">Add to Whitelist</button>
                    </div>

                    {/* Display error if IP is invalid */}
                    {error && <p className="text-red-500 mt-2">{error}</p>}

                    <div className="flex flex-row items-center justify-between mt-5">
                        <div className="flex flex-col w-full space-y-2">
                            <h1 className="text-2xl">Whitelisted Servers</h1>
                            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                                {ips.map((ip, index) => (
                                    <div key={index + ip} className="w-full px-5 py-2 flex flex-row items-center justify-between rounded-xl bg-[#343434]">
                                        <div className="flex flex-col items-start justify-start">
                                            <p className="mr-7 text-2xl">{ip}</p>
                                            <div className="flex flex-row items-center justify-center mt-1">
                                                <p className="mr-2">Notes:</p>
                                                <input
                                                    className="bg-[#545454] rounded-lg text-white pl-2 w-full"
                                                    key={ip}
                                                    value={pluginNotes[ip] || ''}
                                                    onChange={(event) =>
                                                        setPluginNotes(prevNotes => ({
                                                            ...prevNotes,
                                                            [ip]: event.target.value
                                                        }))
                                                    }
                                                />                                            </div>
                                        </div>
                                        <MdClose onClick={() => setIps((prevIps) => prevIps.filter((i) => i !== ip))} size={25} className="cursor-pointer" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {comparableIps != ips && (
                        <button
                            onClick={() => {
                                setSavingChanges(true);

                                // post to axios
                                axiosInstance.post(`https://lode.gg/api/users/plugins/${sessionData.id}/update`, { ips, notes: pluginNotes })
                                    .then(({ data }) => {
                                        setSavingChanges(false);

                                        if (data.error) {
                                            setError(data.error);
                                        } else {
                                            setIps([]);
                                            window.location.reload();
                                        }
                                    }).catch(() => {
                                        setError("Our API is currently down. Please try again later.");
                                        setSavingChanges(false)
                                    });
                            }}
                            className={`relative right-0 mt-5 text-black px-5 py-2 rounded-xl w-[200px] ${savingChanges ? "cursor-not-allowed bg-gray-400" : "bg-green-400"}`}>Save Changes</button>
                    )}
                </div>
            </div>
            <h1 className="text-2xl sm:text-4xl mt-10 text-center">Don't know how to whitelist a server?</h1>
            <p className="text-md sm:text-xl max-w-md text-center text-gray-300 mt-2 mb-5">Take a look at our tutorial video to showcase how you can whitelist your server.</p>
            <div className="bg-[#121212] border border-quaternary-color px-5 py-5 w-[80rem] rounded-xl mt-5">
                <div className="flex flex-col items-center justify-center w-full">
                    <iframe className="rounded-3xl" allowFullScreen={true} width="1100" height="619" src="https://www.youtube.com/embed/BIW0vu8-Gr4?si=PnLZLOngtxqsezgs" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                </div>
            </div>
        </div>}
    </div>

}