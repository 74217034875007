import React, { useRef, useState } from "react";
import html2canvas from "html2canvas";
import { FFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

export default function GlintOverlayExport() {
    const containerRef = useRef(null);
    const canvasRef = useRef(null);
    const [generating, setGenerating] = useState(false);
    const [baseImage, setBaseImage] = useState(null);
    const [glintImage, setGlintImage] = useState(null);

    const handleFileUpload = (e, setImage) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setImage(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const exportWebP = async () => {
        if (!containerRef.current || !canvasRef.current || !baseImage || !glintImage) return;
        setGenerating(true);

        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const frameBuffers = [];

        for (let i = 0; i < 20; i++) {
            const glint = containerRef.current.querySelector(".glint");
            if (glint) {
                const progress = i / 19;
                const offsetX = Math.round(-256 * progress);
                const offsetY = Math.round(256 * progress);
                glint.style.backgroundPosition = `${offsetX}px ${offsetY}px`;
            }

            const capture = await html2canvas(containerRef.current, {
                backgroundColor: null,
            });
            ctx.clearRect(0, 0, 256, 256);
            ctx.drawImage(capture, 0, 0, 256, 256);

            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/webp'));
            const buffer = await blob.arrayBuffer();
            frameBuffers.push({ name: `frame-${i}.webp`, buffer });
        }

        const ffmpeg = new FFmpeg();
        await ffmpeg.load();

        for (const frame of frameBuffers) {
            await ffmpeg.writeFile(frame.name, new Uint8Array(frame.buffer));
        }

        const inputs = frameBuffers.flatMap(f => ["-i", f.name]);
        const filter = frameBuffers.map((_, i) => `[${i}:v]`).join("") + `concat=n=${frameBuffers.length}:v=1[out]`;

        const cmd = [
            ...inputs,
            "-filter_complex",
            `${filter},fps=10,format=yuva420p`,
            "-map", "[out]",
            "-loop", "0",
            "output.webp",
        ];

        await ffmpeg.exec(cmd);
        const data = await ffmpeg.readFile("output.webp");

        const url = URL.createObjectURL(new Blob([data.buffer], { type: "image/webp" }));
        const a = document.createElement("a");
        a.href = url;
        a.download = "glint-animation.webp";
        a.click();

        setGenerating(false);
    };

    return (
        <div className="flex flex-col items-center gap-4 p-4">
            <div className="flex flex-col gap-2">
                <label className="font-medium">Choose Base Image:</label>
                <input type="file" accept="image/*" onChange={(e) => handleFileUpload(e, setBaseImage)} />

                <label className="font-medium">Choose Glint Texture:</label>
                <input type="file" accept="image/*" onChange={(e) => handleFileUpload(e, setGlintImage)} />
            </div>

            {baseImage && glintImage && (
                <>
                    <div
                        ref={containerRef}
                        className="relative w-[256px] h-[256px] border mt-4"
                    >
                        <img
                            src={baseImage}
                            alt="Base"
                            className="absolute w-full h-full object-cover"
                        />
                        <div
                            className="glint absolute w-full h-full opacity-50"
                            style={{
                                backgroundImage: `url(${glintImage})`,
                                backgroundRepeat: "repeat",
                                backgroundPosition: "0px 0px",
                                mixBlendMode: "screen",
                                animation: "glintScroll 2s linear infinite",
                            }}
                        ></div>
                    </div>

                    <canvas
                        ref={canvasRef}
                        width={256}
                        height={256}
                        className="mt-4 border"
                    />
                </>
            )}

            {baseImage && glintImage && (
                <button
                    className="px-4 py-2 bg-purple-600 text-white rounded-md mt-4"
                    onClick={exportWebP}
                    disabled={generating}
                >
                    {generating ? "Generating..." : "Export Animated WebP"}
                </button>
            )}

            <style>
                {`
          @keyframes glintScroll {
            0% { background-position: 0px 0px; }
            100% { background-position: -256px 256px; }
          }
        `}
            </style>
        </div>
    );
}
