import LegalSidebar from "../../components/LegalSidebar";

export default function Copyright() {
  return (
    <div>
      <div className="flex flex-row items-start justify-center">
        <LegalSidebar active="COPYRIGHT" />
        <div className="ml-2 bg-[#3e3e3e] rounded-2xl px-5 py-3 w-[50rem]">
          <h1 className="text-3xl inter-bold">Copyright</h1>
          <p className="text-gray-300">Last Modified at 9/12/24 2:39 PM</p>
          <hr className="my-2"></hr>
          <p>
            <strong>Copyright © 2025 LODESTONE SERVICES LIMITED LIABILITY COMPANY. All rights reserved.</strong>
          </p>
          <p className="mt-5">
            All content and materials (excluding "Mojang" content) on this Website, including but not limited to text, graphics, logos, and images, are the property of LODESTONE SERVICES LIMITED LIABILITY COMPANY or its content suppliers and are protected by United States and international copyright laws.
          </p>
          <p className="mt-5">
            You may not reproduce, distribute, modify, or otherwise use any content from this Website without the express written permission of LODESTONE SERVICES LIMITED LIABILITY COMPANY. For permission requests, please contact us at business@lodestone.to.
          </p>
          <p className="mt-5">
            All trademarks, service marks, and trade names used on the Website are the trademarks or registered trademarks of their respective owners. The use of any trademarks or trade names on the Website does not imply endorsement or affiliation with LODESTONE SERVICES LIMITED LIABILITY COMPANY.
          </p>
          <p className="mt-5">
            If you believe that any content on our Website infringes your copyright, please contact us at business@lodestone.to with a detailed description of the alleged infringement.
          </p>
        </div>
      </div>
    </div>
  );
}