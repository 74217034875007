import { SiSpigotmc } from "react-icons/si";
import { FaRegPaperPlane, FaStar } from "react-icons/fa";
import { LuScroll } from "react-icons/lu";
import { GrBucket } from "react-icons/gr";
import Loading from '../assets/loading.gif';
import { TbCube } from "react-icons/tb";
import { titleCase } from "../util/util";

const LOADER_CLASS_NAME = "items-center justify-center flex flex-row mr-3 rounded-2xl text-[12px]";
const MODRINTH_URL = "https://modrinth.com/plugin";

function filterSerach(str, search) {
    return search.length === 0 ||
        str.id.toLowerCase().includes(search.toLowerCase()) ||
        str.title.toLowerCase().includes(search.toLowerCase()) ||
        (str.categories ?? []).find(c => c.toLowerCase().includes(search.toLowerCase())) ||
        (str.loaders ?? []).find(l => l.toLowerCase().includes(search.toLowerCase()));
}

function getLoader(loader) {
    switch (loader.toUpperCase()) {
        case "PAPER":
            return <div className={`${LOADER_CLASS_NAME} text-[#eaa]`}>
                <FaRegPaperPlane size={25} className="mr-2 min-w-[25px]" />
                <p className="text-[15px]">{titleCase(loader)}</p>
            </div>
        case "BUKKIT":
            return <div className={`${LOADER_CLASS_NAME} text-[#f6af7b]`}>
                <GrBucket size={25} className="mr-2 min-w-[25px]" />
                <p className="text-[15px]">{titleCase(loader)}</p>
            </div>
        case "FABRIC":
            return <div className={`${LOADER_CLASS_NAME} text-[#dbb69b]`}>
                <LuScroll size={25} className="mr-2 min-w-[25px]" />
                <p className="text-[15px]">{titleCase(loader)}</p>
            </div>
        case "SPIGOT":
            return <div className={`${LOADER_CLASS_NAME} text-[#f1cc84]`}>
                <SiSpigotmc size={25} className="mr-2 min-w-[25px]" />
                <p className="text-[15px]">{titleCase(loader)}</p>
            </div>
        case "PURPUR":
            return <div className={`${LOADER_CLASS_NAME} text-[#c3abf7]`}>
                <TbCube size={25} className="mr-2 min-w-[25px]" />
                <p className="text-[15px]">{titleCase(loader)}</p>
            </div>
    }
}

export default function PluginCatalogue({
    pluginStatistics,
    search,
    pluginIps
}) {
    {/* List */ }
    return <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {pluginStatistics.length === 0 && <div className="flex flex-row items-center justify-center ">
            <img className="mt-5" src={Loading} width={50} />
        </div>}
        {pluginStatistics
            .filter((p) => (!pluginIps || pluginIps[p.id]) && filterSerach(p, search))
            .map((plugin) => {
                return (
                    <div
                        key={plugin.id}
                        className="rounded-xl bg-[#121212] border border-quaternary-color px-5 py-5 mx-1 my-1"
                    >
                        <div className="mx-4 flex flex-col items-start justify-between">
                            <div className="flex flex-col md:flex-row items-start md:items-center justify-center">
                                <img
                                    alt="logo of plugin"
                                    src={plugin.icon_url}
                                    width={85}
                                    className="rounded-xl lg:w-[6rem] mr-5 mb-6 md:mb-0"
                                />
                                <div>
                                    <div className="justify-start flex-row items-center">
                                        <div className="flex flex-row items-end justify-start">
                                            <h1 className="text-3xl mr-2">{plugin.title}</h1>
                                            <p className="text-gray-300 text-xl">{plugin.version}</p>
                                        </div>
                                        <div className="mt-3 items-start justify-start text-gray-300 flex-row grid md:grid-cols-2 3xl:grid-cols-3">
                                            {(plugin.loaders ?? []).map(loader => getLoader(loader))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="text-gray-400 text-xl">{plugin.description}</p>
                            <ActionButtons pluginIps={pluginIps} plugin={plugin} />
                        </div>
                    </div>
                );
            })}
    </div>
}

function ActionButtons({ pluginIps, plugin }) {
    return pluginIps ? <div className="mt-3 w-full space-y-2">
        <a
            target="_blank"
            rel="noreferrer"
            href={`https://lode.gg/dashboard/${plugin.id}/download`}
            className={`font-extrabold text-black bg-green-400 px-3 py-2 rounded-xl flex items-center justify-center`}
        >
            Download
        </a>
        <a
            rel="noreferrer"
            href={`/dashboard/plugins/${plugin.id}/edit`}
            className="font-extrabold text-black bg-yellow-400 w-full px-3 py-2 rounded-xl flex items-center justify-center"
        >
            Configure Plugin
        </a>
        {plugin.wiki_url && (
            <a
                rel="noreferrer"
                target="_blank"
                href={plugin.wiki_url}
                className="hidden md:flex font-extrabold text-black bg-orange-400 w-full px-3 py-2 rounded-xl items-center justify-center"
            >
                Read Documentation
            </a>
        )}</div> : <div className="mt-3 w-full space-y-2">
        <a
            rel="noreferrer"
            href={!plugin.paid ? `${MODRINTH_URL}/${plugin.id}` : `https://lode.gg/shop/${plugin.id}/checkout`}
            className={`font-extrabold text-black ${plugin.paid ? "bg-red-400" : "bg-green-400"} w-full px-3 py-2 rounded-xl flex items-center justify-center`}
        >
            {plugin.paid ? `Purchase for $${plugin.price}` : "View on Modrinth"}
        </a>
        {plugin.wiki_url && (
            <a
                rel="noreferrer"
                target="_blank"
                href={plugin.wiki_url}
                className="font-extrabold text-black bg-orange-400 w-full px-3 py-2 rounded-xl flex items-center justify-center"
            >
                Read Documentation
            </a>
        )}
    </div>;
}