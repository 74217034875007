import axios from "axios";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { axiosInstance, titleCase } from "../util/util";
import PluginCatalogue from "../components/PluginCatalogue";
import { PageContext } from "../page-context";

const MODRINTH_API = "https://lode.gg/api/modrinth";

export default function Plugins() {
  const [search, setSearch] = useState("");
  const [plugins, setPlugins] = useState([]);

  const [pluginStatistics, setPluginStatistics] = useState([]);

  const { screenSize } = useState(PageContext);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/landing/plugins").then((res) => setPlugins(res?.data ?? [])).catch(() => null);
  }, []);

  useEffect(() => {
    const fetchPluginData = async () => {
      const pluginDataPromises = plugins.map(async (plugin) => {
        try {
          if (plugin.paid) return plugin;

          const pluginRes = await axios.get(`${MODRINTH_API}/${plugin.id}`);
          const { description, downloads, icon_url, title, wiki_url, categories, loaders } =
            pluginRes.data;

          const versionRes = await axios.get(
            `${MODRINTH_API}/${plugin.id}/version`
          );
          const versions = versionRes.data[0]?.game_versions || [];
          const version = versionRes.data[0]?.version_number || "Unknown";

          return {
            categories,
            loaders,
            versions,
            description,
            downloads,
            icon_url,
            title,
            version,
            id: plugin.id,
            wiki_url,
          };
        } catch (error) {
          console.error(`Error fetching data for plugin ${plugin.id}:`, error);
          return null;
        }
      });

      const pluginData = await Promise.all(pluginDataPromises);
      setPluginStatistics((prev) => [...prev, ...pluginData.filter(Boolean)]);
    };

    if (plugins.length > 0) fetchPluginData();
  }, [plugins]);

  return (
    <div className="flex flex-row items-center justify-center">
      {/* Content */}
      <div className="ml-5 flex flex-col items-center justify-center mt-10 w-[95%] md:w-[70%]">
        <div className="items-center justify-center flex-col flex">
          <h1 className="mb-2 text-4xl font-bold">Browse Our Plugins</h1>
          <p className="text-xl text-gray-300 text-center">View our catalogue of plugins that will help you with your journey of setting up and hosting events!</p>
          <div className="text-gray-300 flex flex-row items-center justify-start w-full md:w-[70%] my-3 bg-[#121212] border-2 border-[#262626] px-5 py-2 rounded-xl">
            <BiSearch size={25} className="cursor-pointer" onClick={() => setSearch("")} />
            <input
              className="bg-transparent lg:ml-3 focus:outline-none w-full"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              value={search}
            />
          </div>
          <PluginCatalogue
            plugins={plugins}
            pluginStatistics={pluginStatistics}
            search={search}
          />
        </div>
      </div>
    </div>
  );
}
