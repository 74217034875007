import { BsDiscord } from "react-icons/bs";
import Banner from "../assets/banner.svg";
import { PageContext } from "../page-context";
import { useContext, useEffect, useState } from "react";
import { axiosInstance, LODE_API } from "../util/util";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaPlus, FaRegBell, FaRegCompass, FaRegUser } from "react-icons/fa";
import { FiLogOut, FiSettings, FiShoppingCart } from "react-icons/fi";
import { RiShoppingCart2Line } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";

const NavbarComponent = ({ className }) => {

  return <div className={className}>
    <div className="flex flex-row items-center justify-center space-x-10 text-gray-300">
      <a href="/" className="hover:text-gray-100 transition-colors">Home</a>
      <a href="/events" className="hover:text-gray-100 transition-colors">Events</a>
      <a href="/plugins" className="hover:text-gray-100 transition-colors">Plugins</a>
      <a href="/discord" className="hover:text-gray-100 transition-colors" target="_blank">Discord</a>
      <a href="/store" className="hover:text-gray-100 transition-colors flex flex-row items-center justify-center">
        <FiShoppingCart className="mr-2" />
        Store
      </a>
    </div>
  </div>
}

const DropdownComponent = ({ isNavOpen }) => {
  return <div className={`z-10 absolute w-48 h-68 top-24 space-y-2 border-quaternary-color border rounded-xl bg-primary-color p-5 ${isNavOpen ? "block" : "hidden"}`}>
    <a href="/dashboard/profile" className="flex flex-row items-center justify-start transition-colors hover:text-[#ffffffcd]">
      <FaRegUser className="mr-2" />
      Profile
    </a>
    <a href="/dashboard/notifications" className="flex flex-row items-center justify-start transition-colors hover:text-[#ffffffcd]">
      <FaRegBell className="mr-2" />
      Notifications
    </a>
    <a href="/dashboard/plugins" className="flex flex-row items-center justify-start transition-colors hover:text-[#ffffffcd]">
      <FiSettings className="mr-2" />
      Dashboard
    </a>
    <hr />
    <a href="/store" className="flex flex-row items-center justify-start transition-colors hover:text-[#ffffffcd]">
      <RiShoppingCart2Line className="mr-2" />
      Store
    </a>
    <a href="/events" className="flex flex-row items-center justify-start text-orange-400 transition-colors hover:text-[#fb923ccd]">
      <FaRegCompass className="mr-2" />
      Apply for Events
    </a>
    <hr />
    <a href="/dashboard/events" className="flex flex-row items-center justify-start text-green-400 transition-colors hover:text-[#4ade80cd]">
      <FaPlus className="mr-2" />
      Host an Event
    </a>
    <a href="/logout" className="flex flex-row items-center justify-start text-red-400 transition-colors hover:text-[#f87171cd]">
      <FiLogOut className="mr-2" />
      Log Out
    </a>
  </div>
}

export default function Navbar() {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const controller = new AbortController();
    axiosInstance.get(LODE_API + "/landing/user", { signal: controller.signal })
      .then((res) => setUserData(res.data))
      .catch(() => null);
    return () => controller.abort();
  }, []);

  const { screenSize } = useContext(PageContext);
  const [isNavOpen, setNavOpen] = useState(false);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      {screenSize < 1024 && isMobileNavOpen && <div className="bg-[black] fixed pt-32 z-5 w-full h-full px-6">
        <h1 className="text-gray-400 text-md">Product</h1>
        <ul className="space-y-3 my-2 text-2xl">
          <li className="hover:text-gray-400 transition-colors"><a href="/">Home</a></li>
          <li className="hover:text-gray-400 transition-colors"><a href="/events">Events</a></li>
          <li className="hover:text-gray-400 transition-colors"><a href="/plugins">Plugins</a></li>
          <li className="hover:text-gray-400 transition-colors"><a href="/store">Store</a></li>
        </ul>
        <h1 className="text-gray-400 text-md mt-8">Resources</h1>
        <ul className="space-y-3 my-2 text-2xl">
          <li className="hover:text-gray-400 transition-colors"><a href="/discord" target="_blank">Discord</a></li>
          <li className="hover:text-gray-400 transition-colors"><a href="/careers">Careers</a></li>
          <li className="hover:text-gray-400 transition-colors"><a href="/contact">Contact</a></li>
          <li className="hover:text-gray-400 transition-colors"><a href="/legal/terms">Terms of Service</a></li>
          <li className="hover:text-gray-400 transition-colors"><a href="/legal/refund-policy">Refund Policy</a></li>
          <li className="hover:text-gray-400 transition-colors"><a href="/legal/privacy-policy">Privacy Policy</a></li>
        </ul>
      </div>}
      <div className={`flex flex-row items-center justify-center w-full ${screenSize < 1024 && isMobileNavOpen ? "fixed z-10" : "relative"}`}>
        <div className={`border bg-[#121212] border-quaternary-color py-3 px-5 w-full lg:w-[65%] flex flex-row items-center justify-between my-5 rounded-xl`}>
          <div className="w-full flex flex-col items-center justify-center">
            <div className="w-full flex flex-row items-center justify-between">
              <a href="/">
                <img src={Banner} alt="lodestone banner" width={140}></img>
              </a>
              <NavbarComponent className="hidden lg:block" />
              <div className="space-x-3 flex flex-row items-center justify-center">
                {
                  userData ? <button onClick={() => setNavOpen(p => !p)} className="flex flex-row items-center justify-center">
                    <img src={userData.icon_url} className="w-8 rounded-full" />
                    <span className="text-gray-300 ml-2">{userData.id}</span>
                    <MdKeyboardArrowDown className="ml-1" />
                    <DropdownComponent isNavOpen={isNavOpen} />
                  </button> : <a href="/login" className="flex flex-row items-center justify-center transition-colors opacity-90 hover:opacity-100 bg-white text-black py-2 px-4 rounded-xl">
                    {/* <BsDiscord className="text-discord-color mr-2" /> */}
                    Log in
                  </a>
                }
                {isMobileNavOpen && <IoMdClose onClick={() => setMobileNavOpen(p => !p)} size={30} className={`block lg:hidden cursor-pointer transition-transform`} />}
                {!isMobileNavOpen && <RxHamburgerMenu onClick={() => setMobileNavOpen(p => !p)} size={30} className={`block lg:hidden cursor-pointer transition-transform`} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
