import { useEffect, useState } from "react";
import axios from "axios";
import { Dialog } from "@headlessui/react";
import { axiosInstance } from "../util/util";

export default function FaqDashboard() {
    const [faqs, setFaqs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingFaq, setEditingFaq] = useState(null);
    const [form, setForm] = useState({ questions: [""], answer: "", isGlobal: false, guildId: null });

    useEffect(() => {
        fetchFaqs();
    }, []);

    const fetchFaqs = async () => {
        const res = await axiosInstance.get("https://lode.gg/api/faqs");
        setFaqs(res.data.map(faq => {
            return {
                ...faq,
                questions: faq.questions.map(q => !q ? "" : q).map(q => q.trim()),
            };
        }));
    };

    const openModal = (faq = null) => {
        if (faq) {
            setEditingFaq(faq);
            setForm({
                questions: [...faq.questions],
                answer: faq.answer,
                isGlobal: faq.isGlobal,
                guildId: faq.guildId || null,
            });
        } else {
            setEditingFaq(null);
            setForm({ questions: [""], answer: "", isGlobal: false, guildId: null });
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setEditingFaq(null);
    };

    const handleSubmit = async () => {
        if (!form.questions.length || !form.answer) return;

        if (editingFaq) {
            await axiosInstance.post(`https://lode.gg/api/faqs/${editingFaq._id}`, {
                questions: form.questions.map(q => !q ? "" : q),
                answer: form.answer,
                isGlobal: form.isGlobal,
                guildId: form.guildId,
            });
        } else {
            await axiosInstance.post("https://lode.gg/api/faqs", {
                questions: form.questions.map(q => !q ? "" : q),
                answer: form.answer,
                isGlobal: form.isGlobal,
                guildId: form.guildId || null,
            });
        }

        fetchFaqs();
        closeModal();
    };

    const handleDelete = async (id) => {
        await axiosInstance.delete(`https://lode.gg/api/faqs/${id}`);
        fetchFaqs();
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">FAQ Dashboard</h1>
            <button className="bg-blue-500 text-white px-4 py-2 rounded mb-4" onClick={() => openModal()}>
                + Add New FAQ
            </button>

            <ul>
                {faqs.map((faq) => (
                    <li key={faq._id} className="border p-4 mb-4 rounded">
                        <ul className="mb-2">
                            {faq.questions.map((q, i) => (
                                <li key={i}><strong>Q{i + 1}:</strong> {q}</li>
                            ))}
                        </ul>
                        <textarea className="border p-2 w-full mb-2" readOnly value={faq.answer} rows={faq.answer.split("\n").length} />
                        <p className="text-sm text-gray-500">{faq.isGlobal ? "🌐 Global" : `Guild ID: ${faq.guildId}`}</p>
                        <div className="mt-2">
                            <button className="bg-yellow-500 text-white px-2 py-1 mr-2" onClick={() => openModal(faq)}>
                                Edit
                            </button>
                            <button className="bg-red-500 text-white px-2 py-1" onClick={() => handleDelete(faq._id)}>
                                Delete
                            </button>
                        </div>
                    </li>
                ))}
            </ul>

            <Dialog open={showModal} onClose={closeModal} className="fixed z-10 inset-0 overflow-y-auto bg-gray-600">
                <div className="flex items-center justify-center min-h-screen px-4">
                    <Dialog.Panel className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                        <Dialog.Title className="text-lg font-bold mb-4 text-black">{editingFaq ? "Edit FAQ" : "New FAQ"}</Dialog.Title>

                        {form.questions.map((q, i) => (
                            <input
                                key={i}
                                className="border p-2 w-full mb-2"
                                placeholder={`Question variation ${i + 1}`}
                                value={q}
                                onChange={(e) => {
                                    const updated = [...form.questions];
                                    updated[i] = e.target.value;
                                    setForm({ ...form, questions: updated });
                                }}
                            />
                        ))}
                        <div className="flex gap-2 mb-2">
                            <button
                                className="bg-gray-700 text-white px-2 py-1 rounded"
                                onClick={() => setForm({ ...form, questions: [...form.questions, ""] })}
                            >
                                + Add Question
                            </button>
                            <button
                                className="bg-gray-700 text-white px-2 py-1 rounded"
                                onClick={() => {
                                    const updated = [...form.questions];
                                    updated.pop();
                                    setForm({ ...form, questions: updated });
                                }}
                                disabled={form.questions.length <= 1}
                            >
                                - Remove Question
                            </button>
                        </div>

                        <textarea
                            className="border p-2 w-full mb-2"
                            placeholder="Answer"
                            value={form.answer}
                            onChange={(e) => setForm({ ...form, answer: e.target.value })}
                        />

                        <input
                            className="border p-2 w-full mb-2"
                            placeholder="Guild ID (optional)"
                            value={form.guildId || ""}
                            onChange={(e) => setForm({ ...form, guildId: e.target.value })}
                        />

                        <label className="block mb-4">
                            <input
                                type="checkbox"
                                checked={form.isGlobal}
                                onChange={(e) => setForm({ ...form, isGlobal: e.target.checked })}
                            /> {" "}
                            Global
                        </label>

                        <div className="flex justify-end gap-2">
                            <button className="bg-gray-400 text-white px-4 py-2 rounded" onClick={closeModal}>Cancel</button>
                            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleSubmit}>
                                {editingFaq ? "Save Changes" : "Add FAQ"}
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>
    );
}
