import LegalSidebar from "../../components/LegalSidebar";

export default function Terms() {
  return (
    <div>
      <div className="flex flex-col md:flex-row items-start justify-center">
        <LegalSidebar active="TERMS" />
        <div className="md:ml-2 bg-[#3e3e3e] rounded-2xl px-5 py-3 w-full md:w-[50rem]">
          <h1 className="text-3xl inter-bold">Terms of Service</h1>
          <p className="text-gray-300">Last Modified at 9/12/24 2:39 PM</p>
          <hr className="my-2"></hr>
          <p>
            Welcome to{" "}
            <strong>LODESTONE SERVICES LIMITED LIABILITY COMPANY </strong>
            ("Lodestone", "Lodestone Services", "lode.gg", "lode", "we", "us",
            or "our"). By accessing or using our services at lode.gg,
            lodestone.to, or mindofneo.com ("Website"), you agree to comply with
            and be bound by the following Terms & Conditions ("Terms", or
            "Agreement"). Please read these Terms carefully. If you do not agree
            to these Terms, do not use our Website.
          </p>
          <p className="text-2xl mt-5 inter-bold">
            <strong>1. Definitions</strong>
          </p>
          <ul>
            <li>
              <strong className="text-xl">Subscription</strong>: The action of
              purchasing a plan that provides access to activating a premium
              plan for Minecraft servers as well as other external benefits
              ("Discord").
            </li>
            <li>
              <strong className="text-xl">User</strong>: An individual or entity
              that visits our website.
            </li>
            <li>
              <strong className="text-xl">Customer</strong>: An individual or
              entity that subscribes ("Membership") to one of our plans.
            </li>
            <li>
              <strong className="text-xl">Plugin</strong>: Products within our
              website or external websites ("Modrinth") that we sell or give out
              to our Users.
            </li>
          </ul>

          <p className="text-2xl mt-5 inter-bold">
            <strong>2. Use of Our Services</strong>
          </p>
          <p>
            By using our Website and services, you agree to:
            <ul>
              <li>Use our services only for lawful purposes.</li>
              <li>
                Not use our services in any way that could damage, disable, or
                impair the Website or interfere with any other party’s use of
                the Website.
              </li>
              <li>
                Not attempt to gain unauthorized access to our systems or
                networks.
              </li>
            </ul>
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>3. Subscription and Payment</strong>
          </p>
          <p>
            <ul>
              <li>
                Subscription fees are billed in advance and are non-refundable,
                except as required by law.
              </li>
              <li>
                We may change the subscription fees or introduce new charges at
                any time, with notice provided to you.
              </li>
            </ul>
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>4. Intellectual Property</strong>
          </p>
          <p>
            All content, trademarks, and other intellectual property on our
            Website are owned by or licensed to Lodestone. You may not use any
            of these without our prior written consent.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>5. Termination</strong>
          </p>
          <p>
            We may terminate or suspend your access to our services if you
            breach these Terms or for any other reason. Upon termination, you
            must stop using our services and destroy any copies of our content
            in your possession.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>6. Limitation of Liability</strong>
          </p>
          <p>
            Our liability is limited to the maximum extent permitted by law. We
            are not liable for any indirect, incidental, or consequential
            damages arising from your use of our services.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>7. Changes to Terms</strong>
          </p>
          <p>
            We may update these Terms from time to time. Out of courtesy, we may
            notify you of any changes by posting the new Terms on our Website.
            But we aren't obligated to do so. Your continued use of our services
            after such changes constitutes your acceptance of the new Terms.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>8. Contact Us</strong>
          </p>
          <p>
            If you have any questions about these Terms, please contact us at
            business@lodestone.to
          </p>
          <p className="mt-5">
            By accessing and using our Website, you agree to be bound by these
            Terms, our <a href="/legal/privacy">Privacy Policy</a>, <a href="/legal/refund">Refund Policy</a>, and any additional terms
            and conditions that may apply to specific sections of the Website or
            to products and services available through the Website.
          </p>
        </div>
      </div>
    </div>
  );
}
