import axios from "axios";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { axiosInstance, MODRINTH_API, titleCase } from "../../util/util";
import PluginCatalogue from "../../components/PluginCatalogue";
import { PageContext } from "../../page-context";

export default function PluginDashboard() {

  const [plugins, setPlugins] = useState([]);
  const [pluginIps, setPluginIps] = useState({});
  const [pluginStatistics, setPluginStatistics] = useState([]);
  const [search, setSearch] = useState("");

  const { screenSize } = useState(PageContext);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/landing/plugins").then((res) => setPlugins(res?.data ?? [])).catch(() => null);
  }, []);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/users/plugins").then((res) => {
      const data = res?.data;
      setPluginIps(data.plugins ?? {});
    }).catch(() => null);
  }, [pluginStatistics]);

  useEffect(() => {
    const fetchPluginData = async () => {
      const pluginDataPromises = plugins.map(async (plugin) => {
        try {
          if (plugin.paid) return plugin;

          const pluginRes = await axios.get(`${MODRINTH_API}/${plugin.id}`);
          const { description, downloads, icon_url, title, wiki_url, categories, loaders } =
            pluginRes.data;

          const versionRes = await axios.get(
            `${MODRINTH_API}/${plugin.id}/version`
          );
          const versions = versionRes.data[0]?.game_versions || [];
          const version = versionRes.data[0]?.version_number || "Unknown";

          return {
            categories,
            loaders,
            versions,
            description,
            downloads,
            icon_url,
            title,
            version,
            id: plugin.id,
            wiki_url,
          };
        } catch (error) {
          console.error(`Error fetching data for plugin ${plugin.id}:`, error);
          return null;
        }
      });

      const pluginData = await Promise.all(pluginDataPromises);
      setPluginStatistics((prev) => [...prev, ...pluginData.filter(Boolean)]);
    };

    if (plugins.length > 0) fetchPluginData();
  }, [plugins]);

  return (
    <div className="flex flex-row items-center justify-center">
      {/* Content */}
      <div className="ml-5 flex flex-col items-center justify-center mt-10 w-[95%] md:w-[70%]">
        <h1 className="mb-2 text-4xl font-bold">Plugin Dashboard</h1>
        <p className="text-xl text-gray-300 text-center">Purchased one of our premium plugins? Select one of these plugins to configure!</p>
        <div className="text-gray-300 flex flex-row items-center justify-start w-full md:w-[70%] my-3 bg-[#121212] border-2 border-[#262626] px-5 py-2 rounded-xl">
          <BiSearch size={25} className="cursor-pointer" onClick={() => setSearch("")} />
          <input
            className="bg-transparent lg:ml-3 focus:outline-none w-full"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            value={search}
          />
        </div>
        <div className="lg:items-start items-center justify-center flex-col lg:flex-row flex mb-12">
          {plugins.length === 0 && <div className="flex flex-row items-center justify-center text-3xl">Looks like you do not own any premium plugins!</div>}
          <PluginCatalogue
            plugins={plugins}
            pluginIps={pluginIps}
            pluginStatistics={pluginStatistics}
            screenSize={screenSize}
            search={search}
          />
        </div>
      </div>
    </div>
  );
}
