import LegalSidebar from "../../components/LegalSidebar";

export default function Vulnerabilities() {
  return (
    <div>
      <div className="flex flex-col md:flex-row items-start justify-center">
        <LegalSidebar active="VULNERABILITIES" />
        <div className="md:ml-2 bg-[#3e3e3e] rounded-2xl px-5 py-3 w-full md:w-[50rem]">
          <h1 className="text-3xl inter-bold">Vulnerabilities</h1>
          <p className="text-gray-300">Last Modified at 9/12/24 2:39 PM</p>
          <hr className="my-2"></hr>
          <p>
            If you found any vulnerabilities in our services, please contact us
            at <span className="underline">business@lodestone.to</span>
            <br />
            Please provide a detailed description of the vulnerability and how
            it can be reproduced.
            <br />
            <strong>DO NOT</strong> disclose the vulnerability to the public
            until we have resolved the issue.
          </p>
        </div>
      </div>
    </div>
  );
}
